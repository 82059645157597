// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyASxeK9X9kS6gAUDMooHmtWmqHcW1h68WM",
    authDomain: "swapy-chat.firebaseapp.com",
    projectId: "swapy-chat",
    storageBucket: "swapy-chat.appspot.com",
    messagingSenderId: "713292679364",
    appId: "1:713292679364:web:b3452a9e65113d43d3a191",
    measurementId: "G-9NN7D0WP5R"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export default app;